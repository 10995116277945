$bg-transparent: #fff0;
$bg-light: #fff;
$bg-dark: #007ac9;
$red: #ee0303;
$yellow: #FA8D2A;
$green: #02ba83;
$bg-card: #eee8;
$bg-darkgrey: #4a5568;
$bg-lightgray: #f0f0f0;


$bg-lightblue: #cfe9f8;
$bg-darkblue: #007bc6;

$font-normal: 1.2rem;
$font-title: 1.5rem;

$padding-normal: 0.5rem;
$padding-break: 1rem;
$padding-big-break: 4rem;

$indent : 20px