@import '../../00_assets/colors.scss';

.commentContainer {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    background-color: white;
}

.textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid $bg-darkblue;
    border-radius: 8px;
    font-size: 1em;
    resize: none;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}