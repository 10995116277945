@import "../../00_assets/colors.scss";

.textarea {
  background-color: white;
  width: 100%;
  padding: 0 10px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
}
