@import '../00_assets/colors.scss';

.WaveContainer{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
}

.RegularColor {
    fill: $bg-light;
    background-color: $bg-lightblue;
}

.InvertColor {
    fill: $bg-light;
    background-color: #0000;
    bottom: 0px;
    height:230px;
    position: absolute;
    // padding-bottom: 200px;
}