@import '../00_assets/colors.scss';

.FabContainerFloating{
    color: $bg-light;
    background: $bg-darkblue;
    z-index: 100000;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    transition: all 0.2s ease-in-out;
    right: 20px;
    bottom: 30px;
}

.FabContainer{
    color: $bg-light;
    background: $bg-darkblue;
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
}

.Fab{
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    text-align: center;
    font-size: 25px;
}

.FabTitle{
    margin-top: 10px;
    font-weight: bold;
}

.FabDisabled{
    pointer-events: none;
    background-color: $bg-darkgrey;
}