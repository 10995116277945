@import "../00_assets/colors.scss";

.WallPage ion-content {
  --padding-top: #{$padding-big-break};
}

.WallPage ion-item,
.WallPage ion-content {
  --background: #{$bg-lightblue};
}

.WallPage {
  margin-bottom: 50px;
}
