@import "../../00_assets/colors.scss";

.EmotionTypeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  align-content: center;
}

.SegmentContainer {
  width: 300px;
}

ion-segment-button {
  --indicator-color: transparent !important;
  --indicator-color-checked: transparent !important;
}

ion-segment-button {
  min-height: 32px;
  margin: 0px;
}

ion-segment-button:hover {
  color: $bg-darkblue !important;
}

ion-label {
  font-size: 12px;
  margin: 0px;
}

.ToggleSegment {
  display: flex;
  background-color: $bg-lightgray;
  border-radius: 20px;
  overflow: hidden;
  border: 3px solid $bg-darkblue;
  height: 40px;
}

.ToggleSegmentButton {
  flex: 1;
  text-align: center;
  background-color: $bg-lightgray;
  color: $bg-darkblue;
  cursor: pointer;
}

.ToggleSegmentButtonActive {
  background-color: $bg-darkblue;
  color: $bg-lightgray;
  width: 150px;
}

.ToggleSegmentButtonActive:hover {
  color: white !important;
}

.EmotionCounter {
  padding-right: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.EmotionSelectContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.FlameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.Emotion {
  margin: 10px;
  text-align: center;
}

.EmotionBtn {
  border-radius: 40px;
  margin: 5px;
  width: 100%;
  max-width: 150px;
  font-size: 0.8em;
  color: $bg-darkblue;
  background-color: white;
  font-weight: 700;
}

ion-button[class*="EmotionBtn"] {
  --background: transparent;
  --box-shadow: none;
  --border-width: 0px;
  --border-radius: 40px;
}

ion-button[fill="solid"] {
  background-color: $bg-darkblue !important;
  color: white;
}

.EmotionRangeContainer {
  color: $bg-dark;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.EmotionRage {
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
}

.NoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.SadIcon {
  margin-bottom: 1rem;
  width: 100px;
  height: 100px;
}

.SelectedEmotions {
  margin-top: 20px;
  font-size: 1rem;
  color: $bg-darkblue;
}

.EmotionSelectContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Emotion {
  flex: 1 1 calc(33.333% - 10px);
  margin: 5px;
  max-width: calc(33.333% - 10px);
}

@media (max-width: 450px) {
  .Emotion {
    flex: 1 1 calc(50% - 10px);
    margin: 5px;
    max-width: calc(50% - 10px);
  }
}
