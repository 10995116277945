@import "../00_assets/colors.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  overflow-y: auto;
}

.tabButtons {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.tabButtons ion-button {
  font-size: 20px;
  --background: transparent;
  --color: gray;
  --padding-start: 0;
  --padding-end: 0;
  --box-shadow: transparent;
}

.tabButtons ion-button.active {
  --color: #{$bg-darkblue};
}

.addButton {
  margin-bottom: 50px;
}
