@import '../../00_assets/colors.scss';

.HomeworkCard {
  position: relative;
  cursor: pointer;
  margin: 15px 0;
  box-shadow: var(--ion-box-shadow);
  border-radius: 10px;
  width: 100%; 
  max-width: none; 
  display: flex;
  flex-direction: column; 
}

.HomeworkTitle {
  color: $bg-dark;
  font-weight: bold;
  font-size: $font-title;
  margin-bottom: 15px;
}

.HomeworkDescr {
  text-align: justify;
  font-size: $font-normal;
  text-indent: 20px;
  margin-top: $padding-break;
}

.CornerTooltip {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  right: -10px;
  top: -30px;
}

.ActiveHomework {
  background: $bg-dark;
}

.OvertimeHomework {
  background: $red;
}

.DoneHomework {
  background: $green;
}

.CornerTooltipText {
  color: $bg-light;
  position: absolute;
  width: auto;
  right: 0px;
  top: 10px;
  padding: 5px 10px;
  text-align: right;
  font-weight: bold;
  font-size: 12px;
}

.HomeworkPostsCount {
  color: $bg-light;
  background-color: $bg-dark;
  padding: 6px 8px;
  margin: 0 5px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}


.ActiveHomework {
    background: var(--ion-color-primary);
  }
  
  .OvertimeHomework {
    background: var(--ion-color-danger);
  }
  
  .DoneHomework {
    background: var(--ion-color-success);
  }
  
