@import '../../00_assets/colors.scss';

.EndHomework{
    padding: $padding-normal;
    color: $bg-dark;
}

.EndHomeworkDescr{
    text-indent: $indent;
    color: $bg-dark;
    font-size: $font-normal;  
    margin-top: $padding-big-break; 
    margin-bottom: $padding-big-break; 
    text-align: justify;
    text-justify: inter-word;
}

// .EndHomeworkPosts{
//     margin-bottom: 230px;
// }

.EndHomeworkButtons{
    position: fixed;
    bottom: $padding-big-break;
}

.EndHomeworkButton{
    text-align: center;
}