@import "../../00_assets/colors.scss";

.HomeworkIcon {
  padding-right: $padding-normal;
  color: $bg-dark;
}

.Post {
  width: 100%;
  background-color: $bg-light;
  box-shadow: none;
  border-radius: 10px;
  margin: 0px 0 10px 0;
  padding: 10px;
  color: black;
}

.postDate {
  margin: 0px;
  font-size: 12px;
  text-align: right;
  margin-top: -10px;
}

.postComment {
  text-align: justify;
  font-size: $font-normal;
  text-indent: $indent;
}
