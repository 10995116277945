@import '../00_assets/colors.scss';

.Separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: $padding-break 0px;
    color: $bg-darkgrey;
  }
  
  .Separator::before,
  .Separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }
  
  .Separator:not(:empty)::before {
    margin-right: .25em;
  }
  
  .Separator:not(:empty)::after {
    margin-left: .25em;
  }