@import "../../00_assets/colors.scss";

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.HomeworkTitleContainer {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.HomeworkDetail {
  margin: 70px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  background-color: #fff;
  border-radius: 10px;
  padding: 0 20px 20px 20px;
}

h3 {
  flex: 1;
  margin: 0;
  font-size: 1.2rem;
  color: $bg-darkblue;
  font-weight: 700;
}

.HomeworkDetailDescr {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
  padding-left: 0px;
}

.HomeworkDetailButtons {
  align-items: center;
  margin-bottom: 0px;
}

.actionText {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 10px;
  font-weight: 500;
}

.Fab {
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.HomeworkDetailPosts {
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
  z-index: 10;
  background-color: #d4e8f7;
  cursor: pointer;

  &:hover {
    border-color: rgba(0, 123, 255, 0.3);
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #d4e8f7;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 123, 255, 0.8);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 123, 255, 1);
  }
}

.Post {
  margin-bottom: 10px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $bg-darkblue;
  margin: 10px 0;
}

.CornerTooltip {
  font-weight: 700;
  display: flex;
  align-items: center;
}
