@import "../00_assets/colors.scss";

.paginationContainer {
  width: 100%;
  position: absolute;
  height: 20px;
  left: 50%;
  transform: translate(-50%, 100%);
  color: $bg-darkblue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.paginationMark {
  padding: 0px 10px;
}

.WizardPageBottomCol {
  padding: 0px 10px;
  text-align: center;
}
