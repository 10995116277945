// @font-face {
//   font-family: 'Roboto';
//   src: url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&amp;subset=latin,latin-ext');
// }

// @font-face {
//   font-family: 'Poppins';
//   src: url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&amp;subset=latin,latin-ext');
// }

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&amp;subset=latin,latin-ext");
@import "../00_assets/colors.scss";

body {
  font-family: "Poppins" !important;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.layout-bg-color {
  background-color: #cdeafb;
}

.bg {
  // position: fixed;
  // bottom: 0px;
  // right: 0px;
  // z-index: -100;
  background-color: #cdeafb;
  padding: 0.5rem;
}

.menuLogo {
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px 20px;
}

.page__background {
  // background-color: $bg-transparent !important;
  background-color: $bg-lightblue !important;
}

.page--menu__background {
  background-color: $bg-dark !important;
}

.page--main__content {
  padding: 10px;
  padding-top: $padding-big-break !important;
}

.page--main__background {
  backdrop-filter: blur(10px);
}

.page--main-browser__background {
  // backdrop-filter: blur(10px);
}

.page--main-browser__content {
  padding: 10px;
  padding-top: $padding-big-break !important;
}

.page--toolbar__content {
  padding: 10px;
}

.toolbar--menu {
  background-color: $bg-dark !important;
}

.toolbar--menu__center {
  color: $bg-light !important;
}

.back-button--menu__icon {
  fill: $bg-light !important;
}

.zmdi-menu {
  fill: $bg-light !important;
  color: $bg-light;
}

.list--no-backgroud {
  background-color: $bg-transparent !important;
}

.backgroundb {
  background-color: #0076ff !important;
}

.list--border {
  border: black solid 1px !important;
}

.list--menu {
  background-color: $bg-dark !important;
}

.list-item--material__center {
  padding: 0 !important;
}

.list-item--not-active {
  background-color: $bg-dark;
  color: $bg-light !important;
}

.list-item--nodivider {
  padding: 0 !important;
}

.list-item--active {
  background-color: $bg-light;
  color: $bg-dark;
  font-weight: bold;
}

.button--fund {
  background-color: $red !important;
  border-radius: 20px !important;
}

.button--float-right {
  float: right;
}

.button--icon {
  background-color: $bg-dark !important;
  border-radius: 23px !important;
  padding: 0px 12px !important;
}

.button--login {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.card--homework {
  width: 100%;
  background-color: $bg-light !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  position: relative !important;
  overflow: hidden;
  margin: 0 0 10px 0 !important;
}

.card--nomargin {
  margin: 0px !important;
}

.card--modal {
  width: 500px;
  margin: auto !important;
}

.feelLikeIcon {
  padding: 10px;
}

.feelLiceIcon-container {
  display: flex;
  justify-content: center;
}
.feelLikeIcon-icon {
  width: 35px;
}

.feelLikeIcon-active {
  box-shadow: inset 0 0 19px 15px $bg-light;
  background-color: $bg-dark;
  border-radius: 20px;
}

.emotionIcon {
  padding: 20px;
  margin: 10px;
}

.emotionIcon-active {
  border: black 1px solid;
  border-radius: 10px;
}

textarea {
  width: 100% !important;
  margin: 10px;
}

section {
  margin-top: 30px;
}

.full-width {
  width: 100% !important;
}

.form-padding {
  padding: 10px 0px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.margin {
  margin: 10px;
}

.wall-input {
  margin: 10px;
  width: 90%;
}

.background_darkgrey {
  background-color: $bg-darkgrey !important;
}

.wall_input_section {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.rotate {
  transform: rotate(180deg);
}

.chat-send {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 15px;
  padding-top: 20px;
  background-color: $bg-light;
}

.chat-section {
  margin-bottom: 70px;
}

.chatMsg-sender {
  margin-left: 20% !important;
  background-color: $bg-dark !important;
  color: $bg-light;
  min-width: 80%;
}

.chatMsg-reciver {
  margin-right: 20% !important;
  min-width: 80%;
}

.chat-msg {
  margin-top: 0px;
}

.chat-date {
  font-size: 10px;
  text-align: right;
  margin-bottom: 0px;
}

.list-descr {
  text-align: justify;
  font-size: $font-normal;
  text-indent: $indent;
}

.switch__toggle {
  background-color: $bg-dark !important;
}

.sharedText {
  font-weight: bold;
  font-size: 12px;
}

.recorTitle {
  font-size: 12px;
  margin: 0px;
}

.list-homework-title {
  font-weight: bold;
}

.text-input--chat {
  width: 100% !important;
}

.fab {
  background-color: $bg-dark !important;
  color: $bg-light !important;
}

.fab--floating {
  right: 20px !important;
  position: fixed !important;
  bottom: 60px !important;
}

.icon {
  margin-right: 5px;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.button--pinselector {
  margin: 10px !important;
  font-size: 25px !important;
  padding: 10px 25px !important;
}

.font_roboto {
  font-family: "Roboto", "Noto", sans-serif;
}

.pinselector-container {
}

.pinselector {
  max-width: 300px;
  margin: auto;
}

.pinselector-value {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.pinselector ion-grid ion-row {
  display: flex;
  justify-content: center;
}

.pinselector ion-grid ion-row ion-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-padding-login {
  padding-top: 15px;
  padding-bottom: 15px;
}

.error {
  color: red;
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.underconstructionIcon {
  color: $bg-dark;
  font-size: 100px;
  text-align: center;
}

.underconstructionTitle {
  color: $bg-dark;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.select--homework {
  background-color: $bg-light;
  padding: $padding-normal;
  border-radius: $padding-normal;
  left: $padding-normal;
  right: $padding-normal;
  position: absolute;
}
