@import "../../00_assets/colors.scss";

.feellikeselectorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 150px;
  position: relative;
}

.feellikeselectorWrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 300px;
  justify-content: space-between;
}

.feellikeselectorOption,
.feellikeselectorOptionCheck {
  font-size: 90px;
  padding: 50px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.feellikeselectorOptionCheck {
  transform: scale(1.4);
  color: $bg-darkblue;
}

.feellikeselectorOption {
  color: $bg-darkgrey;
}
