@import "../../00_assets/colors.scss";

.PostDetail {
  padding: $padding-normal;
  padding-top: $padding-big-break;
  color: $bg-dark;
}

.PostDetailDescr {
  text-indent: $indent;
  color: $bg-dark;
  font-size: $font-normal;
  margin-bottom: $padding-big-break;
  text-align: justify;
  text-justify: inter-word;
}

.PostDetailEmotions {
  margin-bottom: 230px;
}

.PostDetailButtons {
  position: fixed;
  bottom: $padding-big-break;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.PostDetailButton {
  text-align: center;
}
