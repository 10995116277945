@import '../../00_assets/colors.scss';

.commentContainer{
    padding: 0 10px;
}

.commentTitle{
    color: $bg-darkblue;
    margin-bottom: 80px !important;
}

