@import '../00_assets/colors.scss';

.FeelLikeIconJoy{
    font-size: 60px;
    color: $green;
    float: left;
    margin-right: 20px;
}

.FeelLikeIconMeh{
    font-size: 60px;
    color: $yellow;
    float: left;
    margin-right: 20px;
}

.FeelLikeIconSad{
    font-size: 60px;
    color: $red;
    float: left;
    margin-right: 20px;
}

