.btnIon {
    --background: #0076ff;
    --border-radius: 4px !important;   
    --color: white;
    font-size: 25px;
    min-height: '52px';
    max-width: '63.91px';   
}
  
.btnIon:active {
    transform: scale(0.95); // click animation
}
  