@import "../00_assets/colors.scss";

.TopBarContainer {
  top: 0px;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.TopBarContainer ion-row ion-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TopBarContainerBackground {
  background-color: $bg-lightblue;
}

.BackBtn {
  font-size: $font-title !important;
  --color: #{$bg-dark};
  --padding-top: 2px;
  --padding-bottom: 2px;
  --padding-start: 2px;
  --padding-end: 2px;
  min-height: 0;
}

.Title {
  color: $bg-dark;
  font-size: $font-title !important;
  font-weight: bold;
  text-align: center;
}
