@import "../../00_assets/colors.scss";

.commentContainer {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  background-color: white;
}

.homeworkAssignOptionCheck {
  padding: 10px;
}

.homeworkAssignOption {
  color: $bg-darkblue;
  padding: 10px;
}

.commentContainer {
  padding: 10px;
}

.homeworkAssignContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.homeworkAssignOption {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    border-color: #007bff;
  }

  input[type="radio"] {
    margin-right: 10px;
  }

  .radioLabel {
    font-size: 1rem;
    color: #333;
  }
}

.selected {
  border-color: #007bff;
  background-color: #e6f7ff;
}
