@import "../00_assets/colors.scss";

.WizardPageTop {
  position: fixed;
  top: $padding-big-break;
}

.WizardPageBottomProgressBar {
  position: fixed;
  width: 100%;
  bottom: 100px;
}

.WizardPageBottom {
  position: fixed;
  bottom: -20px;
}

.WizardPageContent {
  background-color: $bg-lightblue;
  width: 100%;
  top: $padding-big-break;
  min-height: 100vh;
}

.WizardPageBottom {
  bottom: 0px;
}

.WizardPageBottomProgressBar {
  bottom: 120px;
}

.WizardPageChildren {
  max-height: 60vh;
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $bg-darkblue;
    border-radius: 10px;
  }
}

.WizardPageButton[disabled="false"] {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: inherit !important;
}

.Title {
  color: $bg-darkblue;
  margin-bottom: $padding-break !important;
  font-weight: bold !important;
  margin-top: 0px!important;
}

.MainWizardTitle {
  margin-top: 10px;
  color: $bg-darkblue;
  margin-bottom: $padding-big-break !important;
  font-weight: 300;
}
