.DeadlineContainer {
  padding: 0px 20px 0px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DeadlineInput {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
}
